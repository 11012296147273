.adm-list {
  --header-font-size: 15px;
  --prefix-width: 'auto';
  --prefix-padding-right: 12px;
  --align-items: center;
  --active-background-color: var(--adm-border-color);
  --border-inner: solid 1px var(--adm-border-color);
  --border-top: solid 1px var(--adm-border-color);
  --border-bottom: solid 1px var(--adm-border-color);
  --padding-left: 12px;
  --padding-right: 12px;
  --font-size: 17px;
}
.adm-list-header {
  color: var(--adm-color-weak);
  font-size: var(--header-font-size);
  padding: 8px var(--padding-right) 8px var(--padding-left);
}
.adm-list-body {
  background-color: #ffffff;
  overflow: hidden;
  font-size: var(--font-size);
}
.adm-list-body-inner {
  margin-top: -1px;
}
.adm-list-default .adm-list-body {
  border-top: var(--border-top);
  border-bottom: var(--border-bottom);
}
.adm-list-card {
  margin: 12px;
}
.adm-list-card .adm-list-body {
  border-radius: 8px;
}
.adm-list-card .adm-list-header {
  padding-left: 0;
}
.adm-list-item {
  display: block;
  padding-left: var(--padding-left);
  position: relative;
}
.adm-list-item-title,
.adm-list-item-description {
  color: var(--adm-color-weak);
  font-size: 13px;
}
.adm-list-item-content {
  display: flex;
  align-items: var(--align-items);
  justify-content: flex-start;
  border-top: var(--border-inner);
  padding-right: var(--padding-right);
}
.adm-list-item-content-prefix {
  width: var(--prefix-width);
  flex: none;
  padding-right: var(--prefix-padding-right);
}
.adm-list-item-content-main {
  flex: auto;
  padding: 12px 0;
}
.adm-list-item-content-extra {
  flex: none;
  padding-left: 12px;
  font-size: 15px;
  color: var(--adm-color-weak);
}
.adm-list-item-content-arrow {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: var(--adm-color-light);
  font-size: 19px;
}
.adm-list-item-disabled {
  cursor: not-allowed;
}
.adm-list-item-disabled.adm-list-item-disabled > .adm-list-item-content > * {
  opacity: 0.4;
  pointer-events: none;
}
a.adm-list-item:active:not(.adm-list-item-disabled) {
  background-color: var(--active-background-color);
}
a.adm-list-item:active:not(.adm-list-item-disabled)::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  border-bottom: var(--border-inner);
}
